import { useAssociateUserWithAnonymousId } from './useAssociateUserWithAnonymousId';
import { useSendAnonymousIdToApi } from './useSendAnonymousIdToApi';

import { useIdentifyAndExperimentsToSegment } from 'lib/analytics/useIdentifyAndExperimentsToSegment';
import { useAuthContext } from 'lib/context/AuthContext';
import { getAnonymousUserId } from 'lib/multiStorageWrapper/multiStorageWrapper';
import Logger from 'lib/utils/Logger';

/**
 * This component is responsible for identifying the anonymous/current user to various services (internal and external)
 */
const Identifier = (): null => {
  const anonymousUserId = getAnonymousUserId();
  const { currentUserId, email } = useAuthContext();

  if ((!currentUserId && email) || (currentUserId && !email)) {
    Logger.warn(
      `Conflicting states for userId and email: ${currentUserId} <=> ${email}, for ${anonymousUserId}`
    );
  }

  useSendAnonymousIdToApi(anonymousUserId);

  useAssociateUserWithAnonymousId(anonymousUserId, currentUserId);

  useIdentifyAndExperimentsToSegment(
    anonymousUserId,
    currentUserId && email
      ? {
          email,
          userId: currentUserId,
        }
      : undefined
  );

  return null;
};

export default Identifier;
