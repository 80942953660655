import { ApolloCache, DataProxy, InMemoryCache } from '@apollo/client';
import { Entry } from 'contentful';
import isEmpty from 'lodash/isEmpty';

import { GET_STATIC_PAGE_DATA } from 'data/graphql/queries';

import {
  IStaticPageModel,
  loadStaticPageData,
} from 'lib/contentful/_staticPages';
import Logger from 'lib/utils/Logger';

import { GET_CUSTOMER } from '../customer.gql';
export interface IUpdateCustomerInput {
  firstName?: string;
  lastName?: string;

  // 'base64 encoded shopify checkout id';
  shopifyCheckoutId?: string;
}

export const getStaticContentfulData = async (
  cache: ApolloCache<unknown>,
  cacheRootFieldName: string,
  modelConverterFn: (model: Entry<unknown>) => unknown,
  loadDataFn: () => unknown,
  query: DataProxy.Query<unknown, unknown>,
  typename: string,
  variables?: Record<string, never>
) => {
  try {
    // This path will execute when there is no data for footer in the cache (not loaded yet)
    // No Error handling here - we want it to throw on exception
    const contentfulModel = await loadDataFn();
    const convertedContentfulData = modelConverterFn(
      contentfulModel as Entry<unknown>
    );

    cache.writeQuery({
      data: {
        __typename: typename,
        [cacheRootFieldName]: convertedContentfulData,
      },
      query,
      variables,
    });

    return convertedContentfulData;
  } catch (error) {
    Logger.error('Something went wrong running getStaticContentfulData', error);
  }
};

const getStaticPageData = async (
  _: unknown,
  { id }: { id: string },
  { cache }: { cache: ApolloCache<unknown> }
) => {
  return getStaticContentfulData(
    cache,
    'getStaticPageData',
    (model: Entry<IStaticPageModel>): IStaticPageModel => ({ ...model.fields }),
    () => loadStaticPageData(id),
    GET_STATIC_PAGE_DATA,
    'getStaticPageDataResponse',
    { id }
  );
};

const updateCustomer = (
  previous: any, // Result, returned from the previous query in the link stack
  // tslint:disable-next-line
  _args: IUpdateCustomerInput,
  { cache }: { cache: DataProxy }
) => {
  const me = previous?.updateCustomer.customer;
  if (!isEmpty(me)) {
    return cache.writeQuery({
      data: { me },
      query: GET_CUSTOMER,
    });
  }
};

const refreshMe = (
  _: unknown,
  __: unknown, // args
  { cache }: { cache: InMemoryCache }
) => {
  try {
    return cache.readQuery({
      query: GET_CUSTOMER,
    });
  } catch (error) {
    // log, but not as error, since this is normal when user is not logged in
    Logger.log('Error when executing refreshMe mutation');
  }
  return null;
};

export default {
  Mutation: {
    refreshMe,
    updateCustomerLocal: updateCustomer,
  },
  Query: {
    getStaticPageData,
  },
};
