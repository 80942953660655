import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import {
  ComponentType,
  MouseEvent,
  ReactNode,
  useState,
  useEffect,
} from 'react';

import auth from 'lib/auth';
import { useAuthContext } from 'lib/context/AuthContext';

import AppleIcon from 'assets/icons/ic-apple.inline.svg';
import GoogleIcon from 'assets/icons/ic-google.inline.svg';

import styles from './SocialAuthButton.module.scss';

type SocialAuthButtonProps = {
  children: ReactNode;
  provider: CognitoHostedUIIdentityProvider;
  redirectPath?: string;
};

const getProviderIcon = (
  provider: CognitoHostedUIIdentityProvider
): ComponentType<unknown> | null => {
  switch (provider) {
    case CognitoHostedUIIdentityProvider.Google:
      return GoogleIcon;
    case CognitoHostedUIIdentityProvider.Apple:
      return AppleIcon;
    default:
      return null;
  }
};

const SocialAuthButton = (props: SocialAuthButtonProps) => {
  const { clearAuthError } = useAuthContext();
  const { children, provider } = props;
  const { redirectPath } = props;

  const [redirect, setRedirect] = useState<string | undefined>(redirectPath);

  useEffect(() => {
    // Set default redirect path to current page url if one is not provided
    // If login came from login page, do not provide custom redirect path
    const blacklistRedirectPath = ['/', '/login'];
    if (
      window &&
      !redirectPath &&
      !blacklistRedirectPath.includes(location.pathname)
    ) {
      setRedirect(window.location.pathname);
    }
  }, [redirect, redirectPath]);

  if (!provider) {
    return null;
  }

  const handleSocialAuthClick = async (
    event: MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    clearAuthError();
    const stateData = JSON.stringify({ redirect });
    await auth.signInWithProvider({
      customState: stateData,
      provider,
    });
  };

  const ProviderIcon = getProviderIcon(provider);

  return (
    <button className={styles.socialAuthButton} onClick={handleSocialAuthClick}>
      <div className={styles.icon}>{ProviderIcon && <ProviderIcon />}</div>
      <span>{children}</span>
    </button>
  );
};

export default SocialAuthButton;
