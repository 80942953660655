import Logger from 'lib/utils/Logger';

/**
 * A wrapper for window.sessionStorage.
 * This module has two main functions:
 * 1. Safely calls window.sessionStorage on client or server.
 * 2. Enforces a schema for sessionStorage so that it is clear what values are being stored in sessionStorage
 */

export enum SessionStorageKeys {
  // ExperimentExposures that has been logged in this session.
  LoggedExperimentExposures = 'LoggedExperimentExposures',
  // Experiments that are set via query params.
  QueryParamExperiments = 'QueryParamExperiments',
}

export const setItem: typeof window.sessionStorage.setItem = (
  key: SessionStorageKeys,
  value: string
) => {
  try {
    window.sessionStorage.setItem(key, value);
  } catch (error) {
    Logger.error('Error setting value to session storage', error);
  }
};

export const getItem: typeof window.sessionStorage.getItem = (
  key: SessionStorageKeys
) => {
  try {
    return window.sessionStorage.getItem(key);
  } catch (error) {
    Logger.error('Error getting value from session storage', error);
    return null;
  }
};
