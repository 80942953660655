import { ApolloError, useQuery } from '@apollo/client';
import camelCase from 'lodash/camelCase';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import React, { ReactElement, ReactNode, useEffect } from 'react';

import {
  CompactedExperiments,
  DEFAULT_BUCKET,
  DEFAULT_VERSION,
  ExperimentExposureValue,
  ExperimentName,
  Experiments,
  ExperimentValue,
  experimentValueResolvers,
  ExperimentValueType,
  EXPERIMENT_NAMESPACE,
} from './experimentConfig';
import { mergeExperimentParams } from './experimentsFromQueryParams';
import {
  ExperimentValueResolver,
  ExperimentValues,
} from './experimentValueResolver';

import { trackExperimentExposures } from 'lib/analytics';
import { useAuthContext } from 'lib/context/AuthContext';
import { getAnonymousUserId } from 'lib/multiStorageWrapper/multiStorageWrapper';
import {
  getItem,
  SessionStorageKeys,
  setItem,
} from 'lib/sessionStorageWrapper/sessionStorageWrapper';
import { isDefined } from 'lib/typeguards';
import { isBrowser } from 'lib/utils/browser';
import Logger, { printToConsole, SEVERITY_LEVEL } from 'lib/utils/Logger';

import { GET_EXPERIMENTS } from './experiment.gql';

import {
  ExperimentIdType,
  ExperimentResponse,
  QueryExperimentArgs,
} from 'types/generated/api';

export type ExperimentValueResolverProvider = (
  rawValues?: ExperimentValues
) => ExperimentValueResolver<ExperimentValueType>;

/**
 *
 * @param experimentResponse
 * @return Parsed value from the response.
 * If the response is malformed, a default value is returned to avoid breaking user experiences.
 */
export const parseExperiments = (
  experimentResponse?: ExperimentResponse
): Experiments => {
  const rawResult: Record<string, ExperimentValue> = {};
  try {
    for (const experiment of experimentResponse?.experiments || []) {
      if (!experiment || !experiment.name) {
        Logger.error(
          'Experiment with empty name. Please check experiment response.'
        );
        continue;
      }
      const camelCaseName = camelCase(experiment.name);
      if (!Object.values(ExperimentName).includes(camelCaseName)) {
        // Silently skip if it's not a known experiment, as it might be turned on before FE deployment
        continue;
      }
      rawResult[camelCaseName] = {
        bucket: experiment.bucket,
        name: experiment.name,
        value: experimentValueResolvers[camelCaseName](
          experiment.values
        ).value(),
        version: experiment.version,
      };
    }
    // Ensure all the experiments has a fallback default value
    for (const experimentName of Object.values(ExperimentName)) {
      if (
        isNaN(Number(experimentName)) &&
        !rawResult.hasOwnProperty(experimentName)
      ) {
        rawResult[experimentName] = {
          bucket: rawResult[experimentName]?.bucket ?? DEFAULT_BUCKET,
          name: rawResult[experimentName]?.name ?? experimentName,
          value: experimentValueResolvers[experimentName]().value(),
          version: rawResult[experimentName]?.version ?? DEFAULT_VERSION,
        };
      }
    }
  } catch (e) {
    Logger.error(`Experiments parse error: ${experimentResponse} - ${e}`);
  }
  return rawResult as Experiments;
};

const getLoggedExposuresFromSessionStorage = (): string[] => {
  const exposures = getItem(SessionStorageKeys.LoggedExperimentExposures);
  return exposures ? exposures.split(',') : [];
};

/**
 * Log the exposures of the experiments and the values
 * Only log the exposure for once per session.
 * @param experiments entries to be logged
 */
export const logExposures = (
  experiments: ExperimentValue[],
  queries?: QueryExperimentArgs
): void => {
  if (isEmpty(experiments)) {
    return;
  }
  const exposures: ExperimentExposureValue[] = [];
  let hasExposures = false;
  const loggedExposures = getLoggedExposuresFromSessionStorage();
  for (const experiment of experiments) {
    if (
      !experiment ||
      experiment.bucket === DEFAULT_BUCKET ||
      experiment.version === DEFAULT_VERSION
    ) {
      // Unlikely to happen, just skip exposure as the experiment may be deactivated and the code is alive.
      continue;
    }

    const bucketAndVersion = {
      bucket: experiment.bucket,
      name: experiment.name,
      version: experiment.version,
    };
    const fingerprint = `${experiment.name}~${experiment.bucket}~${experiment.version}`;
    if (loggedExposures.includes(fingerprint)) {
      continue;
    }
    hasExposures = true;
    loggedExposures.push(fingerprint);
    exposures.push(bucketAndVersion);
  }
  if (hasExposures) {
    trackExperimentExposures(exposures, queries);
    setItem(
      SessionStorageKeys.LoggedExperimentExposures,
      loggedExposures.toString()
    );
  }
};

/**
 * Use graphql experiments query to pull the experiment flags.
 * This method is exported just for unit testing.
 * Please do *not* use this method directly. Use [useExperiments] instead.
 */
export const useQueryExperiments = (): ExperimentContextValue => {
  const isInBrowser = isBrowser();
  const {
    currentUserId,
    email,
    isLoading: isAuthContextLoading,
  } = useAuthContext();
  const userId = currentUserId || getAnonymousUserId();
  const queries = email
    ? {
        id: email,
        idType: ExperimentIdType.EMAIL,
        namespace: EXPERIMENT_NAMESPACE,
      }
    : {
        id: userId ?? '',
        idType: ExperimentIdType.USER,
        namespace: EXPERIMENT_NAMESPACE,
      };
  const { data, error, loading } = useQuery<{ experiment: ExperimentResponse }>(
    GET_EXPERIMENTS,
    {
      fetchPolicy: 'network-only', // Used for first execution
      nextFetchPolicy: 'cache-and-network', // Used for subsequent executions
      skip: !isInBrowser || isAuthContextLoading || !(userId || email),
      variables: queries,
    }
  );
  if (error) {
    // Upon errors, we log the error but still serve default experiment values, so don't return in this if block.
    Logger.error(`Experiment loading error: ${error}`);
  }
  return {
    error,
    experiments:
      !isAuthContextLoading && !loading && data?.experiment
        ? parseExperiments(data?.experiment)
        : undefined,
    loading: isInBrowser && (isAuthContextLoading || loading),
    queries,
  };
};

/**
 * Get our own AB testing experiments from verishop backend.
 * @param exposures The experiments that need to be recorded as one exposure to the customer
 * @return loading state and the experiments object that provides the experiment values
 * If loading is true, the experiments is undefined
 */
export const useExperiments = (
  ...exposures: ExperimentName[]
): { experiments?: CompactedExperiments; loading: boolean } => {
  return useExperimentsConditionally(true, ...exposures);
};

/**
 * Conditionally call useExperiments. This function should be called where
 * using experiments is conditional. Note that react hooks should always be
 * called at top level, which means we shouldn't call useExperiments inside a
 * conditional block.
 * @param experiments
 * @returns
 */
export const useExperimentsConditionally = (
  shouldUseExperiments: boolean,
  ...exposures: ExperimentName[]
): { experiments?: CompactedExperiments; loading: boolean } => {
  const experimentContext = React.useContext(ExperimentContext);
  // Log exposures only from browser as it uses track events
  const shouldLogExposures =
    shouldUseExperiments &&
    isBrowser() &&
    experimentContext?.experiments &&
    // Only exposure when there is no error
    !experimentContext.error;
  const queries = experimentContext?.queries;
  useEffect(() => {
    const exposureEntries = shouldLogExposures
      ? exposures
          .map(exp => {
            const name = ExperimentName[exp];
            return experimentContext?.experiments?.[name];
          })
          .filter(isDefined)
      : [];
    if (shouldLogExposures && !isEmpty(exposureEntries)) {
      logExposures(exposureEntries, queries);
    }
  }, [shouldLogExposures, exposures, experimentContext?.experiments, queries]);

  if (!shouldUseExperiments) {
    return {
      loading: false,
    };
  }

  if (!experimentContext || experimentContext.loading) {
    return {
      loading: true,
    };
  }
  return {
    experiments: toCompactedExperiments(experimentContext.experiments),
    loading: false,
  };
};

const toCompactedExperiments = (experiments?: Experiments) => {
  if (!experiments) {
    return;
  }
  const rawResult: Record<string, ExperimentValueType> = {};
  for (const key of Object.keys(experiments)) {
    rawResult[key] = experiments[key].value;
  }
  return rawResult as CompactedExperiments;
};

export interface ExperimentProps {
  experiments?: CompactedExperiments;
}

type ExperimentConsumerComponentClass<P> = (props: Readonly<P>) => JSX.Element;

export const withExperimentsConsumer = (...exposures: ExperimentName[]) =>
  function withExperimentConsumerHoc<P>(
    WrappedComponent: React.ComponentType<P & ExperimentProps>
  ): ExperimentConsumerComponentClass<P> {
    return function ExperimentConsumerComponent(props: P): JSX.Element {
      const { experiments, loading } = useExperiments(...exposures);
      return (
        <>
          {!loading && experiments && (
            <WrappedComponent {...props} experiments={experiments} />
          )}
        </>
      );
    };
  };

type ExperimentProviderProps = {
  children: ReactNode;
};

export const ExperimentProvider = ({
  children = null,
}: ExperimentProviderProps): ReactElement => {
  const dataFromQuery = useQueryExperiments();
  // Force remove all undefined values to avoid overwrite the non-empty values by mistake and also satisfy eslint
  const dataFromQueryParams =
    // Query params override the experiment values from server side. Thus when server side query has not finished,
    // no need to parse and override.
    isBrowser() && !dataFromQuery.loading
      ? pickBy(mergeExperimentParams(), value => value !== undefined)
      : {};
  if (!isEmpty(dataFromQueryParams)) {
    printToConsole({
      errorOrMessage: `Experiments value overridden by query params: ${JSON.stringify(
        dataFromQueryParams
      )}`,
      severity: SEVERITY_LEVEL.INFO,
    });
  }
  const overriddenData = dataFromQuery.loading
    ? dataFromQuery
    : {
        ...dataFromQuery,
        experiments: {
          ...dataFromQuery.experiments,
          ...dataFromQueryParams,
        } as Experiments,
      };

  return <Provider value={overriddenData}>{children}</Provider>;
};

type ExperimentContextValue = {
  error?: ApolloError;
  experiments?: Experiments;
  loading: boolean;
  queries?: QueryExperimentArgs;
};

export const ExperimentContext: React.Context<ExperimentContextValue> = React.createContext<
  ExperimentContextValue
>({
  loading: false,
});

const { Provider } = ExperimentContext;
