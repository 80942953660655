import { IncomingMessage } from 'http';
import urlParse from 'url-parse';

import Logger from 'lib/utils/Logger';

export const isBrowser = () => typeof window !== 'undefined';

export const createDatadogElementSelector = (selector: string) => ({
  'data-ddid': selector,
});

export const getQueryParamsFromBrowser = () => {
  try {
    const url = document.location.toString();
    const parsedUrl = urlParse(url, true);
    return parsedUrl.query || {};
  } catch (error) {
    Logger.warn('getQueryParamsFromBrowser is being called on server', error);
    return {};
  }
};

export const checkIsMobile = () => {
  try {
    /*
     * From Mozilla https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent:
     * In summary, we recommend looking for the string “Mobi” anywhere in the User Agent to detect a mobile device.
     */
    return /Mobi/i.test(navigator?.userAgent);
  } catch (_) {
    return false;
  }
};
/**
 * Check if the request came from robots
 * @param req
 * @returns true if window.navigator is empty or matches with UA regex patterns
 */
export const isBrowserNoRobot = (req?: IncomingMessage): boolean => {
  const robots = new RegExp(
    ([
      /adsbot/,
      /mediapartners/,
      /googlebot/,
      /bing/,
      /ahrefsbot/,
      /slurp/,
      /yahoo/,
      /botify/,
      /facebook/,
      /instagram/,
      /pinterest/,
      /reddit/,
      /slack/,
      /twitter/,
      /whatsapp/,
      /youtube/,
      /semrush/,
    ] as RegExp[])
      .map(r => r.source)
      .join('|'),
    'i'
  );

  if (req && req.headers) {
    const agent = req.headers['user-agent']?.toLowerCase();
    return !robots.test(`${agent}`);
  }
  if (
    typeof window !== 'undefined' &&
    typeof window.navigator !== 'undefined'
  ) {
    const agent = navigator.userAgent.toLowerCase();
    return !robots.test(`${agent}`);
  }

  return false;
};

export * from './globalFunctions';
