import { createContext } from 'react';

export type GuestCheckoutContextProps = {
  guestCheckoutWebUrl: string;
  isGuestCheckoutModalShowing: boolean;
  setGuestCheckoutWebUrl: (url: string) => void;
  setIsGuestCheckoutModalShowing: (isShowing: boolean) => void;
};

const defaultValue = {
  guestCheckoutWebUrl: '',
  isGuestCheckoutModalShowing: false,
  setGuestCheckoutWebUrl: () => undefined,
  setIsGuestCheckoutModalShowing: () => undefined,
};

const GuestCheckoutContext = createContext<GuestCheckoutContextProps>(
  defaultValue
);

export default GuestCheckoutContext;
