import { Hub } from '@aws-amplify/core';
import { useRouter } from 'next/router';
import { useEffect, useCallback, ReactNode, useReducer } from 'react';
import urlParse from 'url-parse';

import { trackPageView } from 'lib/analytics';
import auth, { getUserProviderName } from 'lib/auth';
import AuthContext, {
  AuthContextProps,
  SetCurrentUserIdProps,
} from 'lib/context/AuthContext';
import { isBrowser } from 'lib/utils/browser';

const COGNITO_HOSTED_UI_ERROR =
  'Unable to sign in with this method. Please enter your email and password.';
const COGNITO_UNKNOWN_ERROR = 'An unknown error occured.  Please try again';

type AuthReducerState = {
  currentUserId?: string;
  email?: string;
  error?: string;
  isLoading: boolean;
  providerName?: string;
};

enum AuthActionsTypes {
  CLEAR_AUTH_ERROR = 'CLEAR_AUTH_ERROR',
  CLEAR_USER_DATA = 'CLEAR_USER_DATA',
  SET_AUTH_ERROR = 'SET_AUTH_ERROR',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_USER_DATA = 'SET_USER_DATA',
}

type AuthReducerAction =
  | { type: AuthActionsTypes.SET_USER_DATA; value: AuthReducerState }
  | { type: AuthActionsTypes.SET_AUTH_ERROR; value: string }
  | { type: AuthActionsTypes.CLEAR_USER_DATA }
  | { type: AuthActionsTypes.SET_IS_LOADING; value: boolean }
  | { type: AuthActionsTypes.CLEAR_AUTH_ERROR };

const authReducer = (state: AuthReducerState, action: AuthReducerAction) => {
  switch (action.type) {
    case AuthActionsTypes.SET_USER_DATA: {
      return {
        ...state,
        ...action.value,
        error: undefined,
      };
    }
    case AuthActionsTypes.CLEAR_USER_DATA: {
      return {
        ...state,
        currentUserId: undefined,
        email: undefined,
        isLoading: false,
      };
    }
    case AuthActionsTypes.SET_AUTH_ERROR: {
      return {
        ...state,
        error: action.value,
      };
    }
    case AuthActionsTypes.CLEAR_AUTH_ERROR: {
      return {
        ...state,
        error: undefined,
      };
    }
    case AuthActionsTypes.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.value,
      };
    }
    default:
      return state;
  }
};

type AuthProviderProps = {
  children: ReactNode;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const setCurrentUserData = ({
    currentUserId,
    email,
    error,
    isLoading,
    providerName,
  }: SetCurrentUserIdProps) => {
    dispatch({
      type: AuthActionsTypes.SET_USER_DATA,
      value: {
        currentUserId,
        email,
        error,
        isLoading,
        providerName,
      },
    });
  };

  const clearCurrentUserData = (): void =>
    dispatch({ type: AuthActionsTypes.CLEAR_USER_DATA });

  const clearAuthError = () =>
    dispatch({ type: AuthActionsTypes.CLEAR_AUTH_ERROR });

  const authContextInitState: AuthReducerState = {
    currentUserId: undefined,
    error: undefined,
    isLoading: true,
  };

  const router = useRouter();
  const [authState, dispatch] = useReducer(authReducer, authContextInitState);

  const initUser = async () => {
    try {
      const user = await auth.currentAuthenticatedUser();
      const { email, sub: userId } = user.attributes;
      dispatch({
        type: AuthActionsTypes.SET_USER_DATA,
        value: {
          currentUserId: userId,
          email,
          isLoading: false,
          providerName: getUserProviderName(user),
        },
      });
    } catch {
      // Only reset loading state on error.
      dispatch({ type: AuthActionsTypes.SET_IS_LOADING, value: false });
    }
  };

  const handleHubEvent = useCallback(({ payload: { data, event } }) => {
    switch (event) {
      case 'cognitoHostedUI_failure': {
        // PreSignUp+failed+with+error+customer%3A+xxx%40xxx.com+already+exists.
        if (
          data.message.includes('PreSignUp+failed') &&
          data.message.includes('already+exists')
        ) {
          dispatch({
            type: AuthActionsTypes.SET_AUTH_ERROR,
            value: COGNITO_HOSTED_UI_ERROR,
          });
        } else {
          dispatch({
            type: AuthActionsTypes.SET_AUTH_ERROR,
            value: COGNITO_UNKNOWN_ERROR,
          });
        }
      }
      case 'customOAuthState': {
        // data is set as the url from which the user initiated SSO login
        if (data) {
          try {
            const { redirect } = JSON.parse(data);
            if (redirect) {
              window.location.href = redirect;
            }
          } catch {
            return;
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    if (!isBrowser()) {
      return;
    }

    const handleRouteChangeComplete = (url: string) => {
      const parsedUrl = urlParse(url);
      const properties = {
        path: parsedUrl.pathname,
        search: parsedUrl.query,
        url: parsedUrl.href,
      };
      trackPageView({ properties });
    };

    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    initUser();
    trackPageView();

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router]);

  useEffect(() => {
    Hub.listen('auth', handleHubEvent);

    return () => {
      Hub.remove('auth', handleHubEvent);
    };
  }, [handleHubEvent]);

  const authContextValue: AuthContextProps = {
    ...authState,
    clearAuthError,
    clearCurrentUserData,
    setCurrentUserData,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
