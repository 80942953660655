import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import { Fragment } from 'react';

/** Strip linebreaks, remove indentations */
export const minifyStringForHtml = (text: string) => {
  return text
    .replace(/\n/g, '')
    .replace(/\s\s+/g, ' ')
    .trim();
};

export const withLinebreaks = (text: string) => {
  if (isEmpty(text)) {
    return null;
  }
  const fragments = text.trim().split('\n');
  const numFragments = fragments.length;
  return fragments.map((textLine, index) => {
    const isLastFragment = index + 1 === numFragments;
    return (
      <Fragment key={index}>
        {textLine}
        {!isLastFragment && <br />}
      </Fragment>
    );
  });
};

export const withCommaBreaks = (text: string) => {
  if (isEmpty(text)) {
    return null;
  }
  const fragments = compact(text.split('\n'));
  const numFragments = fragments.length;
  return fragments.map((textLine, index) => {
    const isLastFragment = index + 1 === numFragments;
    return (
      <Fragment key={index}>
        {textLine}
        {!isLastFragment && ', '}
      </Fragment>
    );
  });
};

export const round = (value: number, step = 1): number => {
  if (!value) {
    return 0;
  }
  const stepInverse = 1.0 / step;
  return Math.round(value * stepInverse) / stepInverse;
};
