import Link from 'next/link';
import { ReactNode } from 'react';

import { pageRoutes } from 'lib/routes';

type AccountReferralsProps = {
  children: ReactNode;
};

const AccountReferrals = (props: AccountReferralsProps) => {
  const displayUrl = pageRoutes.share.displayUrl();

  return (
    <Link href={displayUrl} passHref prefetch={false}>
      {props.children}
    </Link>
  );
};

export default AccountReferrals;
