import { AppProps } from 'next/app';

import { initApolloProps, initApolloPropsSSR } from './config';
import initializeApollo, { APOLLO_STATE_PROP_NAME } from './initializeApollo';

const verishopClientInit = (pageProps: AppProps['pageProps']) =>
  initializeApollo({
    ...initApolloProps,
    clientId: 'verishopApolloClientBrowser',
    initialState: pageProps[APOLLO_STATE_PROP_NAME],
  });

const verishopServerInit = () =>
  initializeApollo({
    ...initApolloPropsSSR,
    clientId: 'verishopApolloClientSSR',
    ssrMode: true,
  });

export const initApollo = {
  verishopClient: verishopClientInit,
  verishopServer: verishopServerInit,
};
