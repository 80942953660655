import { createContext, useContext } from 'react';

export interface AppConfigContextType {
  appConfig: {
    checkoutHostname: string;
    isBookmarksEnabled: boolean;
    isOAthEnabled: boolean;
    isRewardsEnabled: boolean;
    showPoweredByVerishop: boolean;
  };
  pageConfig: {
    isBookmarksEnabled: boolean;
    showNavigationMenu: boolean;
  };
}

const defaultValue: AppConfigContextType = {
  appConfig: {
    checkoutHostname: 'checkout.verishop.com',
    isBookmarksEnabled: true,
    isOAthEnabled: true,
    isRewardsEnabled: true,
    showPoweredByVerishop: false,
  },
  pageConfig: {
    isBookmarksEnabled: true,
    showNavigationMenu: true,
  },
};

export const AppConfigContext = createContext<AppConfigContextType>(
  defaultValue
);

export function useAppConfigContext() {
  return useContext(AppConfigContext);
}
