// tslint:disable:no-console

import { Event as BugsnagEvent } from '@bugsnag/js';

import Bugsnag from 'lib/utils/bugsnag';

const TRUE_NODE_ENV = process.env.TRUE_NODE_ENV!;

const bugsnagEnvWhitelist = ['beta', 'staging', 'production'];
const consoleEnvWhitelist = ['development', 'staging', 'test'];

const shouldUseConsole = (): boolean =>
  consoleEnvWhitelist.includes(TRUE_NODE_ENV);
const shouldUseBugsnag = (): boolean =>
  bugsnagEnvWhitelist.includes(TRUE_NODE_ENV);

export type ErrorOrMessage = Error | string;

export type NotifyOptions = {
  metaData?: {
    [index: string]: any;
    message?: string;
  };
  severity?: BugsnagEvent['severity'];
};

export enum SEVERITY_LEVEL {
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

type PrintToConsoleProps = {
  error?: Error | null | unknown;
  errorOrMessage: ErrorOrMessage;
  severity: SEVERITY_LEVEL;
};

export const notifyBugsnag = (
  errorOrMessage: ErrorOrMessage,
  error?: Error | null | unknown,
  options: NotifyOptions = {}
) => {
  let reportedError: Error | string;
  const finalOptions = { ...options };

  if (typeof errorOrMessage === 'string' && error) {
    finalOptions.metaData = finalOptions.metaData || {};
    finalOptions.metaData.message =
      finalOptions.metaData.message || errorOrMessage;

    if (!(error instanceof Error) && typeof error !== 'string') {
      reportedError = 'Unknown error';
    } else {
      reportedError = error;
    }
  } else {
    reportedError = errorOrMessage;
  }

  Bugsnag.notify(reportedError, (bugsnagEvent: BugsnagEvent) => {
    bugsnagEvent.severity = finalOptions.severity ?? 'error';

    const metaData = finalOptions.metaData ?? {};
    Object.keys(metaData).forEach(key => {
      const value = metaData[key];
      if (value) {
        bugsnagEvent.addMetadata(key, value);
      }
    });
    bugsnagEvent.errors.forEach(bugsnagError => {
      // We know that error stacktrace generated here includes two frames in Logger.
      bugsnagError.stacktrace = bugsnagError.stacktrace.slice(2);
    });
  });
};

const getConsoleFunction = (severity: SEVERITY_LEVEL) => {
  switch (severity) {
    case SEVERITY_LEVEL.ERROR:
      return console.error;
    case SEVERITY_LEVEL.WARNING:
      return console.warn;
    case SEVERITY_LEVEL.INFO:
      return console.info;
    default:
      return console.log;
  }
};

export const printToConsole = ({
  error,
  errorOrMessage,
  severity,
}: PrintToConsoleProps) => {
  const print = getConsoleFunction(severity);
  if (error) {
    print(`${severity}:`, errorOrMessage, error);
  } else {
    print(`${severity}:`, errorOrMessage);
  }
};

export default class Logger {
  /**
   * logs an error message and/or Error object
   * @param errorOrMessage
   * @param error
   */
  static log(errorOrMessage: ErrorOrMessage, error?: Error | unknown): void {
    const severity = SEVERITY_LEVEL.INFO;
    if (shouldUseConsole()) {
      printToConsole({ error, errorOrMessage, severity });
    }
    if (shouldUseBugsnag()) {
      notifyBugsnag(errorOrMessage, error, {
        severity,
      });
    }
  }

  static warn(errorOrMessage: ErrorOrMessage, error?: Error | unknown): void {
    const severity = SEVERITY_LEVEL.WARNING;
    if (shouldUseConsole()) {
      printToConsole({ error, errorOrMessage, severity });
    }
    if (shouldUseBugsnag()) {
      notifyBugsnag(errorOrMessage, error, {
        severity,
      });
    }
  }
  /**
   * Info will not send to bugsnag
   * @param errorOrMessage
   * @param error
   */
  static info(errorOrMessage: ErrorOrMessage, error?: Error | unknown): void {
    const severity = SEVERITY_LEVEL.INFO;
    if (shouldUseConsole()) {
      printToConsole({ error, errorOrMessage, severity });
    }
  }

  /**
   * For when it's important enough to log in development, but need not be sent to Bugsnag
   */
  static warnConsole(errorOrMessage: ErrorOrMessage, error?: Error): void {
    const severity = SEVERITY_LEVEL.WARNING;
    if (shouldUseConsole()) {
      printToConsole({ error, errorOrMessage, severity });
    }
  }

  static error(
    errorOrMessage: ErrorOrMessage,
    error?: Error | null | unknown,
    options: NotifyOptions = { severity: SEVERITY_LEVEL.ERROR }
  ): void {
    const severity = SEVERITY_LEVEL.ERROR;
    if (shouldUseConsole()) {
      printToConsole({ error, errorOrMessage, severity });
    }

    if (shouldUseBugsnag()) {
      const mergedOptions: NotifyOptions = {
        severity,
        ...options,
      };
      notifyBugsnag(errorOrMessage, error, mergedOptions);
    }
  }
}
