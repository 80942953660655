import { useMutation } from '@apollo/client';
import classnames from 'classnames';
import { FormEvent, ReactElement, useState } from 'react';

import SocialAuthButtons from 'components/authForms/SocialAuthButtons/SocialAuthButtons';
import VerishopAgreementText from 'components/Blurbs/VerishopAgreementText/VerishopAgreementText';
import CtaButton from 'components/Buttons/CtaButton';
import TextInput from 'components/InputFields/TextInput/TextInput';

import {
  trackSignUpAttempt,
  trackSignUpFail,
  trackSignUpSuccess,
} from 'lib/analytics';
import AnalyticsEvent from 'lib/analytics/events';
import { useAppConfigContext } from 'lib/appConfig/appConfigProvider';
import auth, { SignUpAttributes } from 'lib/auth';
import { useAuthContext } from 'lib/context/AuthContext';
import { useTrackEventOnMount } from 'lib/hooks/analytics/useTrackEventOnMount';
import { useEmailInput, usePasswordInput } from 'lib/hooks/form';
import { BaseLink } from 'lib/links';
import { getAssetUrl } from 'lib/routes/metaTags';
import Logger, { ErrorOrMessage } from 'lib/utils/Logger';

import { CREATE_ONBOARDING_DISCOUNT_MUTATION } from './SignUpForm.gql';

import { MutationCreateOnboardingDiscountArgs } from 'types/generated/api';

import styles from './SignUpForm.module.scss';

import sharedStyles from '../sharedStyles.module.scss';
import { handleErrors } from '../utils';

export const SIGNUP_HEADER_TEXT = 'Create your account';
const CTA_TEXT = 'Sign Up';

type SignUpFormProps = {
  className?: string;
  hideLoginLink?: boolean;
  isHeaderHidden?: boolean;
  onLoginLinkClick?(): void;
  onSignUpSuccess?(email: string): void;
  referralCode?: string | null;
  source?: string;
};

// $20 off no min spend.
const REGISTERATION_ONBOARDING_OFFER_TYPE =
  'MOBILE_APP_REGISTRATION_VALUE_20_MIN_0';
const ANNIVERSARY_REWARD_TYPE = 'ANNIVERSARY_REWARD_WITH_TEASER';

const SignUpForm = ({
  className,
  hideLoginLink,
  isHeaderHidden,
  onLoginLinkClick,
  onSignUpSuccess,
  referralCode,
  source,
}: SignUpFormProps): ReactElement => {
  const { clearAuthError, setCurrentUserData } = useAuthContext();
  const {
    appConfig: { showPoweredByVerishop },
  } = useAppConfigContext();
  const [createOnboardingDiscount] = useMutation<
    MutationCreateOnboardingDiscountArgs
  >(CREATE_ONBOARDING_DISCOUNT_MUTATION);

  useTrackEventOnMount(AnalyticsEvent.SIGN_UP_SHOW, {
    source,
  });

  const {
    email,
    emailError,
    handleEmailChange,
    validateEmail,
  } = useEmailInput();
  const {
    handlePasswordChange,
    password,
    passwordError,
    validatePassword,
  } = usePasswordInput();
  const [formError, setFormError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  /**
   * Validates the inputs and returns any validation errors
   */
  const validateAndReturnErrors = () => {
    return validateEmail() || validatePassword();
  };

  const handleOnSubmit = async (e: FormEvent) => {
    e.preventDefault();
    trackSignUpAttempt();

    const validationError = validateAndReturnErrors();
    if (validationError) {
      return;
    }
    clearAuthError();
    setIsLoading(true);
    try {
      const attributes: SignUpAttributes = { email };
      if (referralCode) {
        attributes['custom:signup_referral_code'] = referralCode;
      }
      await auth.signUp(email, password, attributes);
      const user = await auth.signIn(email, password);
      const userId = auth.getUserIdFromUser(user);
      setCurrentUserData({ currentUserId: userId, email, isLoading: false });
      setFormError(null);
      setShowSuccessMessage(true);
      trackSignUpSuccess(userId, source);

      // Create onboarding discount rewards here.
      // If this mutation fails, catch and continue
      // to keep a nice user experience
      if (REGISTERATION_ONBOARDING_OFFER_TYPE) {
        try {
          await createOnboardingDiscount({
            variables: {
              input: {
                anniversaryRewardType: ANNIVERSARY_REWARD_TYPE,
                type: REGISTERATION_ONBOARDING_OFFER_TYPE,
              },
            },
          });
        } catch (error) {
          Logger.error(error as ErrorOrMessage);
        }
      }

      if (onSignUpSuccess) {
        onSignUpSuccess(email);
      }
    } catch (error) {
      const formattedError = handleErrors(error);
      setFormError(formattedError.message);
      setShowSuccessMessage(false);
      trackSignUpFail();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={classnames(sharedStyles.formContainer, className)}>
      {!isHeaderHidden && (
        <div className={sharedStyles.header}>{SIGNUP_HEADER_TEXT}</div>
      )}
      {showPoweredByVerishop && (
        <div className={styles.poweredByVerishop}>
          <img
            alt="Powered by Verishop logo"
            height={50}
            src={getAssetUrl('images/logo-goodful-by-verishop.png')}
            width={134}
          />
        </div>
      )}
      <SocialAuthButtons />
      <form method="post" onSubmit={handleOnSubmit}>
        <div>
          <TextInput
            autoComplete="email"
            errorMessage={emailError}
            label="Email address"
            onChange={handleEmailChange}
            placeholder="you@example.com"
            value={email}
          />
          <TextInput
            autoComplete="current-password"
            className={sharedStyles.inputSpacing}
            errorMessage={passwordError}
            label="Create a password"
            onChange={handlePasswordChange}
            placeholder="Enter 6 characters or more"
            type="password"
            value={password}
          />
        </div>
        <CtaButton
          className={sharedStyles.ctaButton}
          isLoading={isLoading}
          text={CTA_TEXT}
          type="submit"
        />
        {!hideLoginLink && (
          <div className={styles.loginContainer}>
            <div>Already have an account?</div>
            {onLoginLinkClick ? (
              // In the case of a modal, we do not want there to be a link.
              <div
                className={styles.loginLink}
                onClick={onLoginLinkClick}
                onKeyPress={e => e.key === 'Enter' && onLoginLinkClick?.()}
                role="button"
                tabIndex={0}
              >
                Log In
              </div>
            ) : (
              <BaseLink passHref pathName="/login">
                <a href="placeholder">Log in</a>
              </BaseLink>
            )}
          </div>
        )}
        {showSuccessMessage && (
          <div className={sharedStyles.successMessage}>
            You have successfully signed up.
          </div>
        )}
        {formError && (
          <div className={sharedStyles.errorMessage}>{formError}</div>
        )}
        <VerishopAgreementText actionText={`clicking "${CTA_TEXT}"`} />
      </form>
    </div>
  );
};

export default SignUpForm;
