import getConfig from 'config/config';

export const ENV_MAP = {
  development: 'development',
  production: 'production',
  staging: 'staging',
  test: 'test',
};

export const IS_DEVELOPMENT = process.env.TRUE_NODE_ENV === ENV_MAP.development;
export const IS_PRODUCTION = process.env.TRUE_NODE_ENV === ENV_MAP.production;

export const API_HOST = IS_DEVELOPMENT
  ? `${getConfig('baseUrl')}:3001`
  : getConfig('baseUrl');
