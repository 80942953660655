import { ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import merge from 'lodash/merge';

import getConfig from 'config/config';
import { IS_DEVELOPMENT } from 'config/utils/env';
import shopifyResolvers from 'data/graphql/resolvers/shopify';
import verishopResolvers from 'data/graphql/resolvers/verishop';

import auth from '../auth';

const resolvers = merge(shopifyResolvers, verishopResolvers); // Can add/link more resolvers here in the future

// use an absolute URL for our graphql API
const GRAPHQL_URI = getConfig('graphql.verishop.uri');
const DEVELOPMENT_PORT = 3001;
const INTERNAL_API_SUBDOMAIN = 'internal-api';
const API_HOST = IS_DEVELOPMENT
  ? `http://localhost:${DEVELOPMENT_PORT}` // local api endpoint
  : getConfig('baseUrl');
const API_HOST_SSR = IS_DEVELOPMENT
  ? API_HOST
  : `https://${INTERNAL_API_SUBDOMAIN}.${API_HOST.split('.')
      .splice(1)
      .join('.')}`;

const apiClientBaseUrl = getConfig('graphql.verishop.host') ?? API_HOST;
const verishopGraphqlEndpointUrl = `${apiClientBaseUrl}${GRAPHQL_URI}`;
const verishopGraphqlEndpointUrlSSR = `${API_HOST_SSR}${GRAPHQL_URI}`;

const authMiddleware = setContext(
  () =>
    new Promise(success => {
      auth
        .getAccessToken()
        .then(accessToken => {
          success({
            headers: { Authorization: `Bearer ${accessToken}` },
          });
        })
        .catch(() => {
          // TODO: What do we do when user is not logged in?
          success({});
        });
    })
);

const linkMiddleware = ApolloLink.from([authMiddleware]);

const verishopApolloClientOptions = {
  name: 'verishop-web',
  version: getConfig('appVersion'),
};

export const initApolloProps = {
  apolloClientOptions: verishopApolloClientOptions,
  connectToDevTools: IS_DEVELOPMENT,
  enableAPQ: !IS_DEVELOPMENT,
  linkConfig: {
    headers: { accept: 'application/json' },
    uri: verishopGraphqlEndpointUrl,
  },
  linkMiddleware,
  resolvers,
};

export const initApolloPropsSSR = {
  apolloClientOptions: verishopApolloClientOptions,
  connectToDevTools: IS_DEVELOPMENT,
  enableAPQ: !IS_DEVELOPMENT,
  linkConfig: {
    headers: { accept: 'application/json' },
    uri: verishopGraphqlEndpointUrlSSR,
  },
  linkMiddleware,
  resolvers,
};
