import { useRouter } from 'next/router';
import { useCallback } from 'react';

import { resetAnalytics, trackSignOut } from 'lib/analytics';
import auth from 'lib/auth';
import { useAuthContext } from 'lib/context/AuthContext';
import { setLocalCartId as shopifySetCartId } from 'lib/shopify/utils';
import Logger from 'lib/utils/Logger';

export const useSignOut = () => {
  const { clearCurrentUserData } = useAuthContext();
  const router = useRouter();

  const handleSignOut = useCallback(async () => {
    try {
      await auth.signOut();
      clearCurrentUserData();
      shopifySetCartId(undefined);
      trackSignOut();
      resetAnalytics();
      router.push('/');
    } catch (error) {
      Logger.error('Error in useSignOut', error);
    }
  }, [clearCurrentUserData, router]);

  return handleSignOut;
};
