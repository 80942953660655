import PrimaryButton from 'components/Buttons/PrimaryButton';
import SecondaryButton from 'components/Buttons/SecondaryButton';

import styles from './OptionsForm.module.scss';

export const OPTIONS_HEADER_TEXT = 'You have great taste!';

type OptionsFormProps = {
  onCheckoutAsGuestButtonClick(): void;
  onLoginButtonClick(): void;
  onSignUpButtonClick(): void;
};

const OptionsForm = (props: OptionsFormProps) => {
  const {
    onCheckoutAsGuestButtonClick,
    onLoginButtonClick,
    onSignUpButtonClick,
  } = props;

  return (
    <div className={styles.root}>
      <div className={styles.subHeader}>
        Please log in or sign up for faster checkout.
      </div>
      <PrimaryButton className={styles.authButton} onClick={onLoginButtonClick}>
        Log in
      </PrimaryButton>
      <PrimaryButton
        className={styles.authButton}
        onClick={onSignUpButtonClick}
      >
        Create an Account
      </PrimaryButton>
      <div className={styles.dividerContainer}>
        <div className={styles.line} />
        <div className={styles.or}>OR</div>
        <div className={styles.line} />
      </div>
      <SecondaryButton
        className={styles.checkoutButton}
        onClick={onCheckoutAsGuestButtonClick}
      >
        Continue as a Guest
      </SecondaryButton>
    </div>
  );
};

export default OptionsForm;
