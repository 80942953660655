import { useMutation } from '@apollo/client';
import { ReactElement, useContext, useState } from 'react';

import OptionsForm, { OPTIONS_HEADER_TEXT } from './OptionsForm/OptionsForm';
import { navigateToCheckout } from './utils';
import LoginForm, {
  LOGIN_HEADER_TEXT,
} from 'components/authForms/LogInForm/LogInForm';
import SignUpForm, {
  SIGNUP_HEADER_TEXT,
} from 'components/authForms/SignUpForm/SignUpForm';
import Modal from 'components/Modals/Modal/Modal';

import GuestCheckoutContext from 'lib/context/GuestCheckoutContext';
import Logger from 'lib/utils/Logger';

import {
  CREATE_MULTIPASS_URL,
  CreateMultipassUrlResponse,
  CreateMultipassUrlVariables,
} from 'components/Buttons/CheckoutButton/CheckoutButton.gql';

import styles from './GuestCheckoutAuthModal.module.scss';

enum ScreenTypes {
  Login,
  Options,
  SignUp,
}

const GuestCheckoutAuthModal = (): ReactElement => {
  const {
    guestCheckoutWebUrl,
    isGuestCheckoutModalShowing,
    setIsGuestCheckoutModalShowing,
  } = useContext(GuestCheckoutContext);
  const [screenType, setScreenType] = useState(ScreenTypes.Options);

  const showOptionsScreen = () => setScreenType(ScreenTypes.Options);
  const showSignUpScreen = () => setScreenType(ScreenTypes.SignUp);
  const showLoginScreen = () => setScreenType(ScreenTypes.Login);

  const [createMultipassUrl, { error: createMultipassError }] = useMutation<
    CreateMultipassUrlResponse,
    CreateMultipassUrlVariables
  >(CREATE_MULTIPASS_URL);

  if (createMultipassError) {
    Logger.error(
      'Error creating multipass url in GuestCheckoutAuthModal',
      createMultipassError
    );
  }

  const routeAuthenticatedUserToCheckout = async () => {
    try {
      const multipassResponse = await createMultipassUrl({
        variables: { input: { returnUrlPath: guestCheckoutWebUrl } },
      });

      const responseData = multipassResponse?.data?.createMultipassUrl;

      if (!responseData?.success) {
        throw Error(JSON.stringify(responseData?.validationErrors));
      }

      navigateToCheckout(responseData.multipassUrl);
    } catch (error) {
      Logger.error('Error with multipass', error);
    }
  };

  const getFormContent = () => {
    switch (screenType) {
      case ScreenTypes.Login:
        return [
          <LoginForm
            isHeaderHidden
            key={0}
            onSignInSuccess={routeAuthenticatedUserToCheckout}
            onSignUpLinkClick={showSignUpScreen}
          />,
          LOGIN_HEADER_TEXT,
        ];

      case ScreenTypes.SignUp:
        return [
          <SignUpForm
            isHeaderHidden
            key={1}
            onLoginLinkClick={showLoginScreen}
            onSignUpSuccess={routeAuthenticatedUserToCheckout}
          />,
          SIGNUP_HEADER_TEXT,
        ];

      default:
        return [
          <OptionsForm
            key={2}
            onCheckoutAsGuestButtonClick={() =>
              navigateToCheckout(guestCheckoutWebUrl)
            }
            onLoginButtonClick={showLoginScreen}
            onSignUpButtonClick={showSignUpScreen}
          />,
          OPTIONS_HEADER_TEXT,
        ];
    }
  };

  const [form, formHeader] = getFormContent();

  const handleCloseClick = () => {
    setIsGuestCheckoutModalShowing(false);

    // The next time they open the modal they should be on the Options screen
    showOptionsScreen();
  };

  return (
    <Modal
      className={styles.root}
      header={formHeader}
      isOpen={isGuestCheckoutModalShowing}
      isUsingWidthOverride
      onCloseClick={handleCloseClick}
    >
      {form}
    </Modal>
  );
};

export default GuestCheckoutAuthModal;
