import 'components/Grid/setup';
import 'styles/app.scss';

import { ApolloProvider } from '@apollo/client';
import { AppProps } from 'next/app';
import React, { ReactElement, useMemo } from 'react';

import AuthProvider from 'components/AuthProvider/AuthProvider';
import Identifier from 'components/Identifier/Identifier';
import GuestCheckoutAuthModal from 'components/Modals/GuestCheckoutAuthModal/GuestCheckoutAuthModal';
import GuestCheckoutAuthModalProvider from 'components/Modals/GuestCheckoutAuthModal/GuestCheckoutAuthModalProvider';
import getConfig from 'config/config';

import algoliaClient from 'lib/algolia/client';
import { useApollo } from 'lib/apollo/useApollo';
import {
  AppConfigContext,
  AppConfigContextType,
} from 'lib/appConfig/appConfigProvider';
import AlgoliaContext from 'lib/context/AlgoliaContext';
import { ExperimentProvider } from 'lib/experiment';
import Bugsnag from 'lib/utils/bugsnag';

import { getAlgoliaInitialState } from '../lib/algolia/getAlgoliaServerState';
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const BugsnagReactPlugin = Bugsnag.getPlugin('react')!;
const BugsnagClientErrorBoundary = BugsnagReactPlugin.createErrorBoundary(
  React
);

/**
 * App is responsible for rendering page components in `pages/*`.
 * It is mounted once on the client, when the page first loads.
 * It lives for the lifetime of the client; it is never unmounted.
 */
const VerishopApp = (props: AppProps): ReactElement => {
  const { Component, pageProps } = props;

  const apolloClient = useApollo(pageProps);
  const currentUrl = useMemo(
    () => getConfig('baseUrl') + props.router?.asPath,
    [props.router?.asPath]
  );
  const algoliaInitialState = useMemo(() => getAlgoliaInitialState(pageProps), [
    pageProps,
  ]);

  const appConfig: AppConfigContextType = useMemo(
    () => ({
      appConfig: {
        checkoutHostname: 'goodful-checkout.verishop.com',
        isBookmarksEnabled: false,
        isOAthEnabled: false,
        isRewardsEnabled: false,
        showPoweredByVerishop: true,
      },
      pageConfig: {
        isBookmarksEnabled: false,
        showNavigationMenu: true,
      },
    }),
    []
  );

  return (
    <div data-hj-ignore-attributes>
      <AppConfigContext.Provider value={appConfig}>
        <BugsnagClientErrorBoundary>
          <AuthProvider>
            <ApolloProvider client={apolloClient}>
              <ExperimentProvider>
                <GuestCheckoutAuthModalProvider>
                  <AlgoliaContext.Provider
                    value={{
                      algoliaClient,
                      initialState: algoliaInitialState,
                    }}
                  >
                    <Component {...pageProps} currentUrl={currentUrl} />
                  </AlgoliaContext.Provider>
                  <GuestCheckoutAuthModal />
                </GuestCheckoutAuthModalProvider>
              </ExperimentProvider>
              <Identifier />
            </ApolloProvider>
          </AuthProvider>
        </BugsnagClientErrorBoundary>
      </AppConfigContext.Provider>
    </div>
  );
};

export default VerishopApp;
