import { Auth } from '@aws-amplify/auth';
import { FederatedSignInOptions } from '@aws-amplify/auth/lib/types';

import getConfig from 'config/config';

import Logger from 'lib/utils/Logger';

Auth.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: ***,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_PASSWORD_AUTH',

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,
    oauth: {
      clientId: getConfig('cognito.userPoolWebClientId'),
      domain: getConfig('cognito.domain'),
      redirectSignIn: getConfig('cognito.redirectSignIn'),
      redirectSignOut: getConfig('cognito.redirectSignOut'),
      responseType: 'code',
    },

    // REQUIRED - Amazon Cognito Region
    region: getConfig('cognito.region'),

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: 'XX-XXXX-X',
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: getConfig('cognito.userPoolId'),
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: getConfig('cognito.userPoolWebClientId'),
  },
});

export type CognitoError = Error & {
  code: string;
  message: string;
  name: string;
};

export type CognitoUser = {
  attributes: {
    email: string;
    identities?: string;
    sub: string; // Cognito userId
  };
};

const errorCodes = {
  expiredCode: 'ExpiredCodeException',
  invalidParameter: 'InvalidParameterException',
  limitExceeded: 'LimitExceededException',
  notAuthenticated: 'The user is not authenticated',
  userAlreadyExists: 'UsernameExistsException',
  userNotFound: 'UserNotFoundException',
};

// Returns CognitoAccessToken
const getAccessToken = async (): Promise<string> => {
  return Auth.currentSession().then(session =>
    session.getAccessToken().getJwtToken()
  );
};

export interface IChangePasswordProps {
  newPassword: string;
  oldPassword: string;
}

const changePassword = async ({
  newPassword,
  oldPassword,
}: IChangePasswordProps): Promise<string> => {
  return new Promise<'SUCCESS'>(async (resolve, reject) => {
    try {
      const currentUser: CognitoUser = await currentAuthenticatedUser();
      const response = await Auth.changePassword(
        currentUser,
        oldPassword,
        newPassword
      );
      await Auth.signOut({ global: true }); // signout all other sessions
      await signIn(currentUser.attributes.email, newPassword);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const checkIsUserLoggedIn = async (): Promise<boolean> => {
  try {
    await getAccessToken();
    return true;
  } catch {
    // user is not logged in
    return false;
  }
};

const confirmSignUp = (username: string, code: string) => {
  return Auth.confirmSignUp(username.toLowerCase(), code);
};

const currentAuthenticatedUser = (): Promise<CognitoUser> => {
  return Auth.currentAuthenticatedUser();
};

const sendVerificationCode = (): Promise<void> => {
  return Auth.verifyCurrentUserAttribute('email');
};

const signIn = async (
  username: string,
  password: string
): Promise<CognitoUser> => {
  return Auth.signIn(username.toLowerCase().trim(), password);
};

const signInWithProvider = async ({
  customState,
  provider,
}: FederatedSignInOptions): Promise<void> => {
  try {
    await Auth.federatedSignIn({ customState, provider });
  } catch {
    throw new Error(`Federated SignIn error ${provider}:`);
  }
};

export type SignUpAttributes = {
  'custom:signup_referral_code'?: string;
  email: string;
};

const signUp = async (
  username: string,
  password: string,
  attributes: SignUpAttributes
) => {
  attributes.email = attributes.email.toLowerCase().trim();
  const lowerCasedUsername = username.toLowerCase();
  return Auth.signUp({
    attributes,
    password,
    username: lowerCasedUsername,
  });
};

const signOut = async (): Promise<void> => {
  try {
    await Auth.signOut();
    await clearCookies();
  } catch (error) {
    throw new Error('Something went wrong signing out.');
  }
};

const deleteCookieByName = (name?: string | null) => {
  try {
    // Domain needs to be without protocol
    const domainString = getConfig('baseUrl').split('://')[1];
    document.cookie = `${name}= ; Domain=.${domainString}; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  } catch (error) {
    Logger.warn('Unable to clear cookie by name on logout', error);
  }
};

// When switching from Cookies to localStorage, cognito library
// will not clean up the last remaining cookies, so
// this will do it manually
const clearCookies = async () => {
  try {
    const cookieNameRegex = /^CognitoIdentityServiceProvider./i;
    const cognitoCookies = document.cookie
      .split(';')
      .map((cookie: string) => cookie.trim())
      .filter(cookie => cookie.match(cookieNameRegex));

    cognitoCookies.forEach((cookie = '') => {
      // cookie at this point consists of [name=value] based on regex above
      const cookieName = cookie.split('=')[0];
      deleteCookieByName(cookieName);
    });
  } catch (error) {
    Logger.warn('Unable to clear cookies on logout', error);
  }
};

const sendForgotPasswordNotification = (email: string) =>
  Auth.forgotPassword(email.toLowerCase());

const resetPassword = async (
  email: string,
  code: string,
  newPassword: string
): Promise<void> => {
  await Auth.forgotPasswordSubmit(email.toLowerCase(), code, newPassword);
  await Auth.signIn(email.toLowerCase(), newPassword);
  await Auth.signOut({ global: true }); // signout all other sessions
};

const getUserIdFromUser = (user: CognitoUser): string => {
  const userId = user?.attributes?.sub;

  if (!userId) {
    Logger.error(`was not able to find userId for ${JSON.stringify(user)}`);
    return '';
  }

  return userId;
};

type CognitoIdentityProvider = {
  dateCreated: number;
  issuer?: string | null;
  primary: boolean;
  providerName: string;
  providerType: string;
  userId: string;
};

export const getUserProviderName = (user: CognitoUser) => {
  const identities = user?.attributes?.identities;
  if (identities) {
    try {
      const primaryIndentity = JSON.parse(identities)?.find(
        (identity: CognitoIdentityProvider) => identity.primary
      );
      return primaryIndentity.providerName;
    } catch {
      return;
    }
  }
};

export default {
  changePassword,
  checkIsUserLoggedIn,
  confirmSignUp,
  currentAuthenticatedUser,
  errorCodes,
  getAccessToken,
  getUserIdFromUser,
  getUserProviderName,
  resetPassword,
  sendForgotPasswordNotification,
  sendVerificationCode,
  signIn,
  signInWithProvider,
  signOut,
  signUp,
};
