import { isBrowser } from 'lib/utils/browser';
import Logger from 'lib/utils/Logger';

/**
 * A wrapper that allows us to safely access window.VerishopMultiStorage.
 * window.VerishopMultiStorage is set inside of _document.tsx.
 */

export const getAnonymousUserId: Window['VerishopMultiStorage']['getAnonymousUserId'] = () => {
  try {
    if (isBrowser()) {
      return window.VerishopMultiStorage.getAnonymousUserId();
    }
  } catch (error) {
    Logger.error('MultiStorage getAnonymousUserId: ', error);
  }
};
