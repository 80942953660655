import Link from 'next/link';

import { pageRoutes } from 'lib/routes';

type LoginLinkProps = {
  children: any;
};

const LoginLink = (props: LoginLinkProps) => {
  const displayUrl = pageRoutes.auth.login.displayUrl();

  return (
    <Link href={displayUrl} prefetch={false}>
      {props.children}
    </Link>
  );
};

export default LoginLink;
