import { AppProps } from 'next/app';
import { useMemo } from 'react';

import { initApollo } from './index';

export const useApollo = (pageProps: AppProps['pageProps'] = {}) => {
  const store = useMemo(() => initApollo.verishopClient(pageProps), [
    pageProps,
  ]);
  return store;
};
