enum AnalyticsEvent {
  AFFIRM_PDP_BUTTON_CLICKED = 'Affirm PDP button clicked',
  ALGOLIA_SEARCH = 'product_index_searched',
  APPLY_PROMO_CLICKED = 'Apply Promo Clicked',
  APPLY_PROMO_VIEWED = 'Apply Promo Viewed',
  APP_INSTALL_BANNER_SHOWN = 'App Install Banner Shown',
  APP_INSTALL_DISMISS_BANNER = 'App Install Dismiss Banner',
  APP_INSTALL_FROM_BANNER = 'App Install From Banner',
  APP_INSTALL_FROM_POST_PAGE = 'App Install From Post Page',
  APP_INSTALL_OPTED_IN = 'App Install Opted in',
  AUTH_LOGIN_BUTTON_CLICKED = 'Auth login button clicked',
  AUTH_SIGNUP_BUTTON_CLICKED = 'Auth signup button clicked',
  CART_VIEWED = 'Cart Viewed',
  COUPON_APPLIED = 'Coupon Applied',
  COUPON_DENIED = 'Coupon Denied',
  COUPON_ENTERED = 'Coupon Entered',
  COUPON_REMOVED = 'Coupon Removed',
  EXPERIMENT_EXPOSURES = 'Experiment Exposures',
  FBA_CLICK_INVITE_CODE_BANNER = 'FBA Click Invite Code Banner',
  FBA_DISMISS_INVITE_CODE_BANNER = 'FBA Dismiss Invite Code Banner',
  FBA_SHOW_INVITE_CODE_BANNER = 'FBA Show Invite Code Banner',
  FOLLOW = 'Follow',
  HORIZONTAL_NAV_DRESSES_CLICKED = 'Horizontal Nav Dresses Clicked',
  HORIZONTAL_NAV_LIVESTREAM_CLICKED = 'Horizontal Nav Livestream Clicked',
  HORIZONTAL_NAV_NEW_ARRIVALS_CLICKED = 'Horizontal Nav New Arrivals Clicked',
  HORIZONTAL_NAV_WOMEN_CLICKED = 'Horizontal Nav Women Clicked',
  JOIN_EVENT_IN_THE_APP = 'Join Event in the App Tapped',
  LIVE_EVENT_PAGE_VIEWED = 'Live Event Page Viewed',
  LIVE_EVENT_PRODUCT_CLICKED = 'Live Event Product Clicked',
  LIVE_LANDING_CATEGORY_CLICKED = 'Live Landing Category Clicked',
  LIVE_LANDING_PRODUCT_THUMBNAIL_CLICKED = 'Live Landing Product Thumbnail Clicked',
  LOGIN_ATTEMPT = 'Login Attempt',
  LOGIN_FAIL = 'Login Fail',
  LOGIN_SUCCESS = 'Login Success',
  LOYALTY_HUB_SEEN = 'Loyalty Hub Seen',
  NEWSLETTER_SIGNUP_SUCCESS = 'Newsletter Signup Success',
  NEWSLETTER_SIGNUP_VIEWED = 'Newsletter Signup Viewed',
  OFFER_APPLIED = 'Offer Applied',
  OFFER_REMOVED = 'Offer Removed',
  PDP_REVIEW_FORM_CLOSE = 'Close PDP Review Form',
  PDP_REVIEW_FORM_SHOW = 'Show PDP Review Form',
  PRODUCTS_SEARCHED = 'Products Searched',
  PRODUCT_ADDED = 'Product Added',
  PRODUCT_ADDED_FROM_SAVED = 'Product Added From Saved',
  PRODUCT_ADDED_TO_WISHLIST = 'Product Added to Wishlist',
  PRODUCT_CLICKED = 'Product Clicked',
  PRODUCT_DETAIL_SECTION_CLOSED = 'pdp_section_closed',
  PRODUCT_DETAIL_SECTION_OPEN = 'pdp_section_open',
  PRODUCT_DETAIL_SECTION_VISIBLE = 'pdp_section_visible',
  PRODUCT_LISTING_VISIBLE = 'product_listing_visible',
  PRODUCT_LIST_FILTERED = 'Product List Filtered',
  PRODUCT_LIST_SCROLLED = 'Product List Scrolled',
  PRODUCT_RECOMMENDATION_SEE_MORE = 'Product Recommendation See More',
  PRODUCT_REMOVED = 'Product Removed',
  PRODUCT_REMOVED_FROM_WISHLIST = 'Product Removed from Wishlist',
  PRODUCT_SAVED = 'Product Saved',
  PRODUCT_VARIANT_VIEWED = 'Product Variant Viewed',
  PRODUCT_VIEWED = 'Product Viewed',
  RELATED_POST_MODAL_ASSET_CHANGED = 'Post Modal Asset Changed',
  RELATED_POST_MODAL_NAVIGATED = 'Post Modal Navigated',
  RELATED_POST_MODAL_OPENED = 'Post Modal Opened',
  REVIEW_FORM_ERROR = 'Verishop Review Form Error',
  REVIEW_FORM_SUBMITTED = 'Verishop Review Form Submitted',
  REVIEW_VIEWED = 'Review Viewed',
  SIGN_OUT = 'Sign Out',
  SIGN_UP_ATTEMPT = 'Sign Up Attempt',
  SIGN_UP_FAIL = 'Sign Up Fail',
  SIGN_UP_SHOW = 'Sign Up Show',
  SIGN_UP_SUCCESS = 'Sign Up Success',
  UNFOLLOW = 'Unfollow',
  VISUAL_FILTER_HEADER_CLICKED = 'Visual Filter Header Clicked',
  VISUAL_FILTER_ITEM_CLICKED = 'Visual Filter Item Clicked',
  WISHLIST_CREATED = 'Wishlist Created',
  WISHLIST_PRODUCT_ADDED_TO_CART = 'Wishlist Product Added to Cart',
  WISHLIST_REMOVED = 'Wishlist Removed',
  WISHLIST_SET_PRIVATE = 'Wishlist Set Private',
  WISHLIST_SET_PUBLIC = 'Wishlist Set Public',
  WISHLIST_SHARED = 'Wishlist Shared',
  WISHLIST_UPDATED = 'Wishlist Updated',
  WISHLIST_VIEWED = 'Wishlist Viewed',
  WRITE_REVIEW_SHOW_FORM = 'Write Review Form Shown',
  WRITE_REVIEW_SUBMIT = 'Write Review Submitted',
  WRITE_REVIEW_SUBMIT_WITH_INCENTIVE = 'Write Review Submitted with Incentive',
}

export enum AnalyticsSource {
  AUTH_MENU = 'web_mobile_nav_menu',
  BOOKMARK_BUTTON = 'web_bookmark_button',
  BOOKMARK_PAGE_NO_RESULTS = 'no_results_bookmarks',
  CART_PAGE = 'web_cart_page',
  CART_PAGE_NO_RESULTS = 'cart_page_no_results',
  FOLLOW_BUTTON = 'follow_button',
  ORDER_LIST_NO_RESULTS = 'web_order_list_no_results',
  SAVE_FOR_LATER_BUTTON = 'web_save_for_later_button',
  SIGNED_OUT_PLACEHOLDER = 'web_signed_out_placeholder',
  USER_MENU = 'web_user_menu_icon',
  USER_MENU_REWARDS_ICON = 'web_rewards_menu_icon',
}

export default AnalyticsEvent;
