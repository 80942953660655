import { useMutation } from '@apollo/client';

import { SYNC_CART_ID, UPDATE_CUSTOMER } from 'data/graphql/mutations';
import { getCartId } from 'data/graphql/resolvers/shopify';

import auth from 'lib/auth';
import Logger from 'lib/utils/Logger';

import { UpdateCustomerInput } from 'data/graphql/types';

/**
 * This hook will sync the local cartId we get from Shopify with the cartId we get back in the ME query from the API.
 * If the cartId from the API is different than the cartId from Shopify, then we set the local cartId to that of the API.
 * We also merge all products in the local cart onto the cart from the API.
 * However, if the cartId from the API is no longer valid (e.g. the user checked out on ios using the checkout id), we set the cartId on the API to equal the local cartId.
 */
export const useSyncCart = () => {
  const [syncCartIdMutation] = useMutation(SYNC_CART_ID);
  const [updateCustomerMutation, { error }] = useMutation<
    Record<string, unknown>,
    { input: UpdateCustomerInput }
  >(UPDATE_CUSTOMER);

  const syncCart = async (checkoutIdFromApi?: string | null) => {
    let finalCheckoutId = checkoutIdFromApi;

    try {
      // Ensure that the sync does not when the user is not signed in.
      const isUserLoggedIn = await auth.checkIsUserLoggedIn();
      if (!isUserLoggedIn) {
        return;
      }

      const { cartId: currentCheckoutId } = await getCartId();

      if (checkoutIdFromApi && checkoutIdFromApi !== currentCheckoutId) {
        await syncCartIdMutation({
          variables: { newCartId: checkoutIdFromApi },
        });

        // In case the checkoutIdFromApi was invalid (e.g. the user checked out on ios using the checkout id),
        // we want to set the checkoutId on the API to equal our local checkoutId
        const { cartId: checkoutIdPostSync } = await getCartId();
        finalCheckoutId = checkoutIdPostSync;
      }

      if (checkoutIdFromApi !== finalCheckoutId && finalCheckoutId) {
        await updateCustomerMutation({
          variables: { input: { shopifyCheckoutId: finalCheckoutId } },
        });

        if (error) {
          throw new Error(JSON.stringify(error));
        }
      }

      return finalCheckoutId;
    } catch (error) {
      if (error instanceof Error) {
        Logger.error('Error in useSyncCart', error);
      }
    }
  };

  return syncCart;
};
