import { BooleanExperimentValueResolver } from './booleanExperimentValueResolver';
import { ExperimentValueResolverProvider } from './index';
import { PdpRecommendationConfigResolver } from './PdpRecommendationConfigResolver';
import { VisenzeRecommendationConfigResolver } from './VisenzeRecommendationConfigResolver';

import { RecommendedObjectValue } from 'types/app';

// =======================================================================
// Please change below 'IMPLEMENT THIS' instructions to add new experiments
// =======================================================================
// =======================================================================
// IMPLEMENT THIS: Add experiment value UDTs here.
// =======================================================================

/**
 * All allowed experiment value types.
 * It includes the default scalar values, and also support custom defined types.
 * =======================================================================
 * IMPLEMENT THIS: Change below type if a new complex experiment value type is added.
 * =======================================================================
 */
export type ExperimentValueType =
  | string
  | number
  | boolean
  | RecommendedObjectValue
  | RecommendedObjectValue[];

export const DEFAULT_BUCKET = -1;
export const DEFAULT_VERSION = -1;

export const EXPERIMENT_NAMESPACE = 'verishop_web';
/**
 * Return type of the useExperiments() hook.
 * =======================================================================
 * IMPLEMENT THIS: Add the experiments here so that they can be used.
 * The key of the experiments must be the same as in ExperimentName enum
 * =======================================================================
 */
export type CompactedExperiments = {
  [key: string]: ExperimentValueType;
  aaTest: boolean;
  disableAffirm: boolean;
  enableAppDownloadBanner: boolean;
  enableBrandProfileV2: boolean;
  enableHomepageDynamicModule: boolean;
  enablePdpPostRecommendation: boolean;
  enablePercentageDiscountMessage: boolean;
  enablePlpPromoEligibleFilter: boolean;
  enablePostModalV2: boolean;
  enableProfiles: boolean;
  enableReviewIncentive: boolean;
  enableVisualFilter: boolean;
  pdpRecommendationConfig: RecommendedObjectValue;
  removeOptionIfOnlyOne: boolean;
  shouldUseAlgoliaSelectedOptions: boolean;
  showBugSubmissionButton: boolean;
  visenzeRecommendationConfig: RecommendedObjectValue[];
};

export type ExperimentValue = {
  bucket: number;
  name: string;
  value: ExperimentValueType;
  version: number;
};

export type ExperimentExposureValue = {
  bucket: number;
  name: string;
  version: number;
};

export type Experiments = {
  [key: string]: ExperimentValue;
};

/**
 * All active experiments' names.
 * =======================================================================
 * IMPLEMENT THIS: Add the experiment names here so that they can be used.
 * The experiment name in graphql response are in snake case, and the name
 * here should be the corresponding camel case form.
 * e.g. abc_xyz => abcXyz
 * =======================================================================
 */
export enum ExperimentName {
  aaTest,
  disableAffirm,
  enableAppDownloadBanner,
  enableBrandProfileV2,
  enableHomepageDynamicModule,
  enablePdpPostRecommendation,
  enablePercentageDiscountMessage,
  enablePlpPromoEligibleFilter,
  enablePostModalV2,
  enableProfiles,
  enableReviewIncentive,
  enableVisualFilter,
  pdpRecommendationConfig,
  removeOptionIfOnlyOne,
  shouldUseAlgoliaSelectedOptions,
  showBugSubmissionButton,
  visenzeRecommendationConfig,
}

/**
 * Register the resolvers to parse the experiment values from the raw json.
 * As we are using graphql as the experiment value provider, all the experiment
 * values are strings. Thus, we need to tell the resolver how to convert them into
 * correct types to be directly usable in `Experiments`.
 * =======================================================================
 * IMPLEMENT THIS: Add the resolvers here.
 * If it's a simple scalar value (int, float, string or boolean), use the provided
 * `*ExperimentValueResolver` and provide a default value.
 * If it's a complex UDT, you need to provide your own resolver how to parse the
 * json object into a strong type UDT.
 * =======================================================================
 */
export const experimentValueResolvers: Record<
  string,
  ExperimentValueResolverProvider
> = {
  aaTest: BooleanExperimentValueResolver.provider(
    ExperimentName[ExperimentName.aaTest]
  ),
  disableAffirm: BooleanExperimentValueResolver.provider(
    ExperimentName[ExperimentName.disableAffirm]
  ),
  enableAppDownloadBanner: BooleanExperimentValueResolver.provider(
    ExperimentName[ExperimentName.enableAppDownloadBanner]
  ),
  enableBrandProfileV2: BooleanExperimentValueResolver.provider(
    ExperimentName[ExperimentName.enableBrandProfileV2],
    true
  ),
  enableHomepageDynamicModule: BooleanExperimentValueResolver.provider(
    ExperimentName[ExperimentName.enableHomepageDynamicModule]
  ),
  enablePdpPostRecommendation: BooleanExperimentValueResolver.provider(
    ExperimentName[ExperimentName.enablePdpPostRecommendation]
  ),
  enablePercentageDiscountMessage: BooleanExperimentValueResolver.provider(
    ExperimentName[ExperimentName.enablePercentageDiscountMessage]
  ),
  enablePlpPromoEligibleFilter: BooleanExperimentValueResolver.provider(
    ExperimentName[ExperimentName.enablePlpPromoEligibleFilter]
  ),
  enablePostModalV2: BooleanExperimentValueResolver.provider(
    ExperimentName[ExperimentName.enablePostModalV2]
  ),
  enableProfiles: BooleanExperimentValueResolver.provider(
    ExperimentName[ExperimentName.enableProfiles]
  ),
  enableReviewIncentive: BooleanExperimentValueResolver.provider(
    ExperimentName[ExperimentName.enableReviewIncentive]
  ),
  enableVisualFilter: BooleanExperimentValueResolver.provider(
    ExperimentName[ExperimentName.enableVisualFilter]
  ),
  pdpRecommendationConfig: PdpRecommendationConfigResolver.provider(),
  removeOptionIfOnlyOne: BooleanExperimentValueResolver.provider(
    ExperimentName[ExperimentName.removeOptionIfOnlyOne]
  ),
  shouldUseAlgoliaSelectedOptions: BooleanExperimentValueResolver.provider(
    ExperimentName[ExperimentName.shouldUseAlgoliaSelectedOptions]
  ),
  showBugSubmissionButton: BooleanExperimentValueResolver.provider(
    ExperimentName[ExperimentName.showBugSubmissionButton]
  ),
  visenzeRecommendationConfig: VisenzeRecommendationConfigResolver.provider(),
};
