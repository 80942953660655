import { ReactNode, useState } from 'react';

import GuestCheckoutContext from 'lib/context/GuestCheckoutContext';

type GuestCheckoutAuthModalProviderType = {
  children: ReactNode;
};

const GuestCheckoutAuthModalProvider = (
  props: GuestCheckoutAuthModalProviderType
) => {
  const [
    isGuestCheckoutModalShowing,
    setIsGuestCheckoutModalShowing,
  ] = useState(false);
  const [guestCheckoutWebUrl, setGuestCheckoutWebUrl] = useState('');
  return (
    <GuestCheckoutContext.Provider
      value={{
        guestCheckoutWebUrl,
        isGuestCheckoutModalShowing,
        setGuestCheckoutWebUrl,
        setIsGuestCheckoutModalShowing,
      }}
    >
      {props.children}
    </GuestCheckoutContext.Provider>
  );
};

export default GuestCheckoutAuthModalProvider;
