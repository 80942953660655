import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import classnames from 'classnames';

import SocialAuthButton from 'components/authForms/SocialAuthButton/SocialAuthButton';

import { useAppConfigContext } from 'lib/appConfig/appConfigProvider';
import { useAuthContext } from 'lib/context/AuthContext';

import styles from './SocialAuthButtons.module.scss';

import sharedStyles from '../sharedStyles.module.scss';

const SocialAuthButtons = () => {
  const { error } = useAuthContext();
  const {
    appConfig: { isOAthEnabled },
  } = useAppConfigContext();

  if (!isOAthEnabled) {
    return null;
  }

  return (
    <div className={styles.socialAuthButtons}>
      <SocialAuthButton provider={CognitoHostedUIIdentityProvider.Google}>
        Continue with Google
      </SocialAuthButton>
      <SocialAuthButton provider={CognitoHostedUIIdentityProvider.Apple}>
        Continue with Apple
      </SocialAuthButton>
      {error && (
        <div className={classnames(sharedStyles.errorMessage, styles.ssoError)}>
          {error}
        </div>
      )}
      <div className={styles.lineWithText}>
        <span>OR</span>
      </div>
    </div>
  );
};

export default SocialAuthButtons;
