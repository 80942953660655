import { useApolloClient } from '@apollo/client';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { FormEvent, FunctionComponent, useState } from 'react';

import SocialAuthButtons from 'components/authForms/SocialAuthButtons/SocialAuthButtons';
import VerishopAgreementText from 'components/Blurbs/VerishopAgreementText/VerishopAgreementText';
import CtaButton from 'components/Buttons/CtaButton';
import TextInput from 'components/InputFields/TextInput/TextInput';
import { GET_ME, GetMeResponse } from 'data/graphql/queries';

import {
  trackLoginAttempt,
  trackLoginFail,
  trackLoginSuccess,
} from 'lib/analytics';
import { useAppConfigContext } from 'lib/appConfig/appConfigProvider';
import auth from 'lib/auth';
import { useAuthContext } from 'lib/context/AuthContext';
import { useSyncCart } from 'lib/hooks/cart/useSyncCart';
import { useEmailInput, usePasswordInput } from 'lib/hooks/form';
import { BaseLink, ForgotPasswordLink } from 'lib/links';
import { getAssetUrl } from 'lib/routes/metaTags';
import keyPressHandler from 'lib/ui/keyPress/keyPressHandler';
import Logger from 'lib/utils/Logger';

import styles from './LogInForm.module.scss';

import sharedStyles from '../sharedStyles.module.scss';

export const GENERIC_LOGIN_ERROR_MESSAGE =
  'Either the user does not exist, or the password was invalid. Please check and try again.';
export const LOGIN_HEADER_TEXT = 'Log into your Verishop account';
const CTA_TEXT = 'Log In';

type LogInFormProps = {
  className?: string;
  isHeaderHidden?: boolean;
  onSignInSuccess?(): void;
  onSignUpLinkClick?(): void;
};

export const LogInForm: FunctionComponent<LogInFormProps> = props => {
  const {
    className,
    isHeaderHidden,
    onSignInSuccess,
    onSignUpLinkClick,
  } = props;

  const {
    appConfig: { showPoweredByVerishop },
  } = useAppConfigContext();

  const { clearAuthError, setCurrentUserData } = useAuthContext();
  const apolloClient = useApolloClient();
  const syncCart = useSyncCart();

  const {
    email,
    emailError,
    handleEmailChange,
    validateEmail,
  } = useEmailInput();
  const {
    handlePasswordChange,
    password,
    passwordError,
    validatePassword,
  } = usePasswordInput(true);
  const [formError, setFormError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  /**
   * Validates the inputs and returns any validation errors
   */
  const validateAndReturnErrors = () => {
    return validateEmail() || validatePassword();
  };

  const handleOnSubmit = async (e: FormEvent) => {
    e.preventDefault();
    clearAuthError();
    trackLoginAttempt();

    const validationError = validateAndReturnErrors();
    if (validationError) {
      return;
    }

    setIsLoading(true);

    try {
      const user = await auth.signIn(email, password);
      const userId = auth.getUserIdFromUser(user);
      const providerName = auth.getUserProviderName(user);

      setCurrentUserData({
        currentUserId: userId,
        email,
        isLoading: false,
        providerName,
      });
      setFormError(null);
      setShowSuccessMessage(true);
      trackLoginSuccess(userId);

      // Bypass the cache and get the latest user.
      const meResponse = await apolloClient.query<GetMeResponse>({
        fetchPolicy: 'network-only',
        query: GET_ME,
      });

      if (isEmpty(meResponse.errors)) {
        const checkoutIdFromApi = meResponse.data?.me?.shopifyCheckoutId;
        // We need to sync the cart before we call onSignInSuccess() in case onSignInSuccess() is updating the cart.
        await syncCart(checkoutIdFromApi);
      } else {
        const error = new Error(JSON.stringify(meResponse.errors));
        Logger.error('Error fetching ME after logging in', error);
      }

      // This should be last, after we have refetched the user and synced the cart
      if (onSignInSuccess) {
        onSignInSuccess();
      }
    } catch (error) {
      setFormError(GENERIC_LOGIN_ERROR_MESSAGE);
      setShowSuccessMessage(false);
      trackLoginFail();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={classnames(sharedStyles.formContainer, className)}>
      {!isHeaderHidden && (
        <div className={sharedStyles.header}>{LOGIN_HEADER_TEXT}</div>
      )}
      {showPoweredByVerishop && (
        <div className={styles.poweredByVerishop}>
          <img
            alt="Powered by Verishop logo"
            height={50}
            src={getAssetUrl('images/logo-goodful-by-verishop.png')}
            width={134}
          />
        </div>
      )}
      <SocialAuthButtons />
      <form
        className={classnames({
          [styles.error]: formError,
        })}
        method="post"
        onSubmit={handleOnSubmit}
      >
        <TextInput
          aria-required="true"
          autoComplete="email"
          errorMessage={emailError}
          label="Email address"
          onChange={handleEmailChange}
          placeholder="you@example.com"
          value={email}
        />
        <TextInput
          aria-required="true"
          autoComplete="current-password"
          className={sharedStyles.inputSpacing}
          errorMessage={passwordError}
          label="Password"
          onChange={handlePasswordChange}
          placeholder="Enter your password"
          type="password"
          value={password}
        />
        <ForgotPasswordLink>
          <a className={styles.forgotPasswordLink} href="placeholder">
            Forgot Password?
          </a>
        </ForgotPasswordLink>
        <CtaButton
          className={sharedStyles.ctaButton}
          isLoading={isLoading}
          text={CTA_TEXT}
          type="submit"
        />
        {showSuccessMessage && (
          <div className={sharedStyles.successMessage}>
            You have successfully logged in.
          </div>
        )}
        {formError && (
          <div className={sharedStyles.errorMessage}>{formError}</div>
        )}
        <div className={styles.signUpContainer}>
          <div>Don&apos;t have a Verishop account?</div>
          {onSignUpLinkClick ? (
            // In the case of a modal, we do not want there to be a link.
            <div
              className={styles.signUpLink}
              onClick={onSignUpLinkClick}
              onKeyPress={keyPressHandler({ Enter: onSignUpLinkClick })}
              role="button"
              tabIndex={0}
            >
              Sign Up
            </div>
          ) : (
            <BaseLink passHref pathName="/signup">
              <a href="placeholder">Sign Up</a>
            </BaseLink>
          )}
        </div>
        <VerishopAgreementText actionText={`clicking "${CTA_TEXT}"`} />
      </form>
    </div>
  );
};

LogInForm.defaultProps = {
  isHeaderHidden: false,
};

export default LogInForm;
