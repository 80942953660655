import { Document } from '@contentful/rich-text-types';
import { Asset, Entry } from 'contentful';

import { client, ContentfulContentTypeEnum } from './index';

const FEATURED_STREAMERS_ID = 'production';

export type StreamerBannerModel = {
  clickUrl: string;
  description: Document;
  id: string;
  name: string;
  profileImage: Asset;
  profileImageMobile: Asset;
  subDescription: string;
};

export type FeaturedStreamersModel = {
  featuredStreamers: Array<Entry<StreamerBannerModel>>;
};

export type FeaturedStreamersEntry = Entry<FeaturedStreamersModel>;

export const loadFeaturedStreamers = async (): Promise<FeaturedStreamersEntry> => {
  const response = await client.getEntries<FeaturedStreamersModel>({
    content_type: ContentfulContentTypeEnum.featuredStreamers,
    'fields.id': FEATURED_STREAMERS_ID,
    include: 3,
    limit: 1,
  });

  return response?.items?.[0];
};
